
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import _ from "lodash";
import { Validation } from "presentation/constant/Validation";
import { CriteriaModel } from "presentation/model/Criteria/CriteriaModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

import { ChargeTypePolicy } from "constants/charge/ChargeTypePolicy";
import { CriteriaExcludedPolicy } from "constants/criteria/CriteriaExcludedPolicy";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { ConstraintDefEntity } from "domain/entity/Criteria/ConstraintDefEntity";
import { ConstraintGroupEntity, EMPTY_CRITERIA_CONSGRP_ENTITY } from "domain/entity/Criteria/ConstraintGroupEntity";
import { CriteriaEntity, EMPTY_CRITERIA_ENTITY } from "domain/entity/Criteria/CriteriaEntity";
import { CriteriaRequestEntity } from "domain/entity/Criteria/CriteriaRequestEntity";
import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CriteriaRepository } from "domain/repository/Criteria/CriteriaRepo";
import { chargeCriteriaValidationSchema, exclCriteriaValidationSchema, genCriteriaValidationSchema, groupListValidationSchema } from "presentation/constant/Criteria/CriteriaValidationSchema";
import { GroupRadioList } from "veronica-ui-component/dist/component/core/GroupRadioButton/GroupRadioButton";

interface CriteriaVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<CriteriaModel>> | ((value: SetStateAction<CriteriaModel>) => void),
    ],
    masterDataRepo: MasterDataRepository,
    criteriaRepo: CriteriaRepository,
    chargeTypeRepo: ChargeTypeRepository,
}

export const CriteriaVM = ({ dispatch, masterDataRepo, criteriaRepo, chargeTypeRepo }: CriteriaVMProps) => {
    const [criteriaDispatch] = dispatch;

    const onShowLoading = () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedRows = async (rows: any[]) => {
        criteriaDispatch(prevState => {

            /*const moveAfterList = prevState.criteriaEntityList?.filter(item => !rows?.some(ety => ety.id === item.id));
            const subMenuItemArray: MenuItem[] = [];

            moveAfterList?.forEach(item => {
                const labelName = item.criteriaName;

                const isExisted = subMenuItemArray?.some(subItem => subItem.label === labelName);
                if (!isExisted) {
                    subMenuItemArray.push({
                        label: labelName,
                        command: () => {
                            handleMoveAfter(rows, item, prevState.entrypoint);
                        }
                    });
                }
            });*/

            return {
                ...prevState,
                selectedRows: rows,
                forceRefresh: !prevState.forceRefresh
                //subMenuItemArray: subMenuItemArray
            }
        })
    }

    // const handleMoveAfter = async (rows: CriteriaEntity[], targetItem: CriteriaEntity, entrypoint: string) => {
    //     let targetCriteriaItems: any[] = [];
    //     let criterRequestEntity: CriteriaRequestEntity = {};

    //     criteriaDispatch(prevState => {
    //         const allDatas = prevState.criteriaEntityList;

    //         const tempCriteriaItems = allDatas?.filter(itemData => !rows?.some(row => itemData.id === row.id));

    //         let targetIndex: number = -1;
    //         for (let i = 0; i < tempCriteriaItems.length; i++) {
    //             const tempRow = tempCriteriaItems[i];

    //             if (targetItem.criteriaName === tempRow.criteriaName) {
    //                 targetIndex = i;
    //             }
    //         }

    //         targetCriteriaItems = [...tempCriteriaItems.slice(0, targetIndex + 1), ...rows, ...tempCriteriaItems.slice(targetIndex + 1, tempCriteriaItems.length)];
    //         criterRequestEntity = { criteriaList: targetCriteriaItems };
    //         return {
    //             ...prevState,
    //             criteriaEntityList: [],
    //             isLoadingMoveAfter: !prevState.isLoadingMoveAfter
    //         }
    //     });


    //     /*await criteriaRepo.onApply(criterRequestEntity).then((data) => {
    //         criteriaDispatch(prevState => {
    //             return {
    //                 ...prevState,
    //                 allFormState: { "applySuccess": "apply successful." }
    //             }
    //         })
    //         criteriaRepo.getCriteriaByCriteriaTypeName(entrypoint).then((data) => {
    //             criteriaDispatch(prevState => {
    //                 return {
    //                     ...prevState,
    //                     criteriaEntityList: data,
    //                     selectedRows: [],
    //                     isLoadingMoveAfter: !prevState.isLoadingMoveAfter
    //                 }
    //             })
    //         }).catch((error) => {
    //             return [];
    //         })
    //     }).catch((error) => {
    //         criteriaDispatch(prevState => {
    //             return {
    //                 ...prevState,
    //                 allFormState: { "applyFail": "apply failed." }
    //             }
    //         })
    //     }).finally(() => {
    //     })*/
    // }

    const onApply = async (updatedRows: CriteriaRequestEntity) => {
        return await criteriaRepo.onApply(updatedRows);
    }

    const onRowDoubleClick = (entity: CriteriaEntity) => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false,
                isShowDetail: true,
                currentSelectedRow: entity,
                masterState: {
                    ...prevState.masterState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSaveClicked: false,
                    isSliderOpen: true,
                    allFormState: {}
                },
                componentState: {
                    ...prevState.componentState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    allFormState: {}
                }
            }
        })
    }

    const loadDropdownOption = async () => {

        await masterDataRepo.getMasterDataByKey(MasterDataType.CHARGE_IND).then(
            chargeInds => {
                const chargeIndDropdownOptions = chargeInds?.map((chargeInd) => ({
                    dropdownLabel: chargeInd.code,
                    tagLabel: chargeInd.code,
                    value: chargeInd.code,
                })) ?? []

                criteriaDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeIndDropdownOptions: [
                            ...chargeIndDropdownOptions
                        ]
                    }
                }))
            }
        )

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOptions: DropdownProps[] = [];
                let subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] } = {};
                newChargeTypes?.forEach(chgTypeEty => {
                    if (chgTypeEty.chargeCategory === ChargeTypePolicy.CHARGE_TYPE_GEN) {
                        const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                            chgType.value === chgTypeEty.chargeType);
                        if (!chgTypeExisted) {
                            chargeTypeDropdownOptions.push({
                                dropdownLabel: chgTypeEty.chargeType,
                                tagLabel: chgTypeEty.chargeType,
                                value: chgTypeEty.chargeType
                            })
                        }
                        if (chgTypeEty.subChargeType) {
                            if (!subChargeTypeDropdownOptions[chgTypeEty.chargeType]) {
                                subChargeTypeDropdownOptions[chgTypeEty.chargeType] = [];
                            }
                            subChargeTypeDropdownOptions[chgTypeEty.chargeType].push({
                                dropdownLabel: chgTypeEty.subChargeType,
                                tagLabel: chgTypeEty.subChargeType,
                                value: chgTypeEty.subChargeType
                            });
                        }
                    }
                });

                criteriaDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chgTypeDropdownOptions: chargeTypeDropdownOptions,
                        subChgTypeDropdownOptions: subChargeTypeDropdownOptions
                    }
                }))
            }
        )
    }

    const loadConsGrpTypeDropdownOption = async (request: CriteriaRequestEntity) => {
        if (request.criteriaTypeName) {
            await criteriaRepo.getConsGrpByCriteriaName(request).then(
                consGrpTypes => {
                    let newChargeInds = _.orderBy(consGrpTypes, ["constraintGroupDefName"]);
                    let hdrGroupList: { [key: string]: string; } = {};
                    let allConstraintDef: ConstraintDefEntity[] = [];
                    let consDefDropdownOptions: { [key: string]: DropdownProps[] } = {};
                    //let consValueDropdownOptions: {[key:string]: DropdownProps[]} = {};

                    newChargeInds?.forEach(consGrpType => {
                        hdrGroupList[consGrpType.id ?? ''] = consGrpType.constraintGroupDefName;

                        consGrpType.constraintDefs?.forEach(consDef => {
                            if (!consDefDropdownOptions[consGrpType.constraintGroupDefName]) {
                                consDefDropdownOptions[consGrpType.constraintGroupDefName] = [];
                            }
                            if (!consDefDropdownOptions[consGrpType.constraintGroupDefName]?.some(item => item.value === consDef.constraintDefDisplayName)) {
                                consDefDropdownOptions[consGrpType.constraintGroupDefName].push({
                                    dropdownLabel: consDef.constraintDefDisplayName,
                                    tagLabel: consDef.constraintDefDisplayName,
                                    value: consDef.constraintDefDisplayName
                                });
                            }
                            if (!allConstraintDef?.some(consDefEty => consDefEty.constraintDefDisplayName === consDef.constraintDefDisplayName)) {
                                allConstraintDef.push(consDef);
                            }

                            /*if (consDef.constraintDefLovList?.length > 0) {
                                if (!consValueDropdownOptions[consDef.constraintDefDisplayName]) {
                                    consValueDropdownOptions[consDef.constraintDefDisplayName] = [];
                                    consDef.constraintDefLovList?.forEach(value => {
                                        if(!consValueDropdownOptions[consDef.constraintDefDisplayName]?.some(v => v.value === value)){
                                            consValueDropdownOptions[consDef.constraintDefDisplayName].push({
                                                dropdownLabel: value,
                                                tagLabel: value,
                                                value: value
                                            });
                                        }
                                    })
                                }
                            }*/
                        });
                    })

                    let consGrpTypeRadioOptions: GroupRadioList[] = _.map(
                        _.toPairs(hdrGroupList),
                        ([key, value], index) => ({
                            inputId: key,
                            key: value,
                            name: value,
                        })
                    );

                    criteriaDispatch(prevState => ({
                        ...prevState,
                        consGrpsTypeDropdownOptions: consGrpTypeRadioOptions,
                        dynamicOptions: {
                            ...prevState.dynamicOptions,
                            consDefDropdownOptions: consDefDropdownOptions,
                            //consValueDropdownOptions: consValueDropdownOptions,
                        },
                        allConstraintDef: allConstraintDef

                    }))
                }
            )
        }
    }

    const onSearchClick = async () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });
    }



    const searchCriteria = async (entrypoint: string) => {
        /*criteriaDispatch(prevState => {
            return {
                ...prevState,
                isBackFromDetail: false,
                selectedRows: [],
                criteriaEntityList: [],
                currentSelectedRow: { ...EMPTY_CRITERIA_ENTITY },
            }
        })*/
        await criteriaRepo.getCriteriaByCriteriaTypeName(entrypoint).then((data) => {
            criteriaDispatch(prevState => {
                return {
                    ...prevState,
                    isBackFromDetail: false,
                    criteriaEntityList: data,
                    selectedRows: [],
                }
            })
        }).catch((error) => {
            return [];
        })

    }


    const onAddClick = () => {
        criteriaDispatch(prevState => ({
            ...prevState,
            //isShowDetail: true,
            currentSelectedRow: EMPTY_CRITERIA_ENTITY,
            masterState: {
                ...prevState.masterState,
                isTabularDataActive: false,
                isAdd: true,
                isRead: false,
                isEditable: false,
                isSaveClicked: false,
                isSliderOpen: true,
                allFormState: {},
                editingCriteriaEntity: EMPTY_CRITERIA_ENTITY,
            },
            componentState: {
                ...prevState.componentState,
                isTabularDataActive: false,
                isAdd: true,
                isRead: false,
                isEditable: false,
                isSliderOpen: false,
                isSelected: false,
                allFormState: {}
            }
        }))
    };

    const onHeaderSingleDropdownChange = (e: any, fieldName: string) => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingCriteriaEntity: {
                        ...prevState.masterState.editingCriteriaEntity,
                        [fieldName]: e.value,
                    }
                }
            }
        });
    };

    const onHeaderMultipleDropdownChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        criteriaDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingCriteriaEntity: {
                    ...prevState.masterState.editingCriteriaEntity,
                    [fieldKey]: val,
                },
                allFormState: {
                    ...prevState.masterState.allFormState,
                    [fieldKey]: '',
                }
            }
        }));
    };

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        criteriaDispatch(prevState => {

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingCriteriaEntity: {
                        ...prevState.masterState.editingCriteriaEntity,
                        [fieldKey]: val,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }

    const onResetClick = async () => {
        criteriaDispatch(prevState => {
            let tempCriteriaEty: CriteriaEntity = EMPTY_CRITERIA_ENTITY;
            if (!prevState.masterState.isAdd) {
                tempCriteriaEty = prevState.currentSelectedRow
            }

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingCriteriaEntity: {
                        ...tempCriteriaEty,
                    },
                }
            }
        })
    }

    const onTempSaveDetail = async (currentTarCodeComp: ConstraintGroupEntity, criteriaId: number | null) => {
        const valResult = await Validation(groupListValidationSchema).ValidateFormOnly(currentTarCodeComp);

        let validatedResult: { [x: string]: string } = {};
        if (valResult) {

            validatedResult = { ...validatedResult, ...valResult, mandatoryCheckFail: 'Please input the missing value.' };

            criteriaDispatch(prevState => {
                return {
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });
            return validatedResult;
        } else {

            criteriaDispatch(prevState => {
                const consGrp = {
                    ...currentTarCodeComp,
                    criteriaId: criteriaId
                }
                let consGrpList: ConstraintGroupEntity[] = [];
                if (prevState.componentState.consGrpList) {
                    consGrpList = prevState.componentState.consGrpList?.filter(item => (item.id !== consGrp.id || item.id === null));
                }
                consGrpList.push(consGrp);

                return {
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        isAdd: false,
                        isEditable: true,
                        isRead: true,
                        isSliderOpen: false,
                        currentSelectItem: EMPTY_CRITERIA_CONSGRP_ENTITY,
                        consGrpList: consGrpList,
                        allFormState: {},
                    }
                }
            });

        }
    }

    const onSave = async (currentCriteriaEty: CriteriaEntity, isAdd: boolean, consGrpList: ConstraintGroupEntity[]) => {
        let schema: { [x: string]: any; } = [];
        if (currentCriteriaEty.criteriaType === 'CHARGE') {
            schema = chargeCriteriaValidationSchema;
        } else if (currentCriteriaEty.criteriaType === 'GCGEN') {
            schema = genCriteriaValidationSchema;
        } else if (currentCriteriaEty.criteriaType === 'CHGEXL' || currentCriteriaEty.criteriaType === 'DOCSEP' || currentCriteriaEty.criteriaType === 'DOCGROUP') {
            schema = exclCriteriaValidationSchema;
        } else {
            schema = chargeCriteriaValidationSchema;
        }
        const valHdrResult = await Validation(schema).ValidateFormOnly(currentCriteriaEty);

        if (valHdrResult) {
            let validatedTariffResult: { [x: string]: string } = {};
            if (valHdrResult) {
                validatedTariffResult = { ...validatedTariffResult, ...valHdrResult, mandatoryCheckFail: 'Please input the missing value.' };
            }

            criteriaDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedTariffResult
                        },

                    }
                }
            });
            return validatedTariffResult;
        } else {

            let res: ResponseEntity;
            if (isAdd) {
                res = await criteriaRepo.createNewCriteria(currentCriteriaEty);
            } else {
                res = await criteriaRepo.updateCriteria(currentCriteriaEty);
            }

            if (res.success) {
                if (res.data && consGrpList && consGrpList.length > 0) {
                    let consGrpListAddId: ConstraintGroupEntity[] = [];
                    consGrpList?.forEach(item => {
                        if (item.criteriaId === null) {
                            consGrpListAddId.push({
                                ...item,
                                criteriaId: res.data.id
                            })
                        } else {
                            consGrpListAddId.push(item);
                        }
                    });
                    await criteriaRepo.addAndUpdateConstraintGroup(consGrpListAddId);
                }
                onCloseClick();
            } else {
                return res.data;
            }
        }
    }

    const onCloseClick = () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackFromDetail: true,
                selectedRows: [],
                currentSelectedRow: { ...EMPTY_CRITERIA_ENTITY },
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isTabularDataActive: true,
                    editingCriteriaEntity: EMPTY_CRITERIA_ENTITY,
                },
                componentState: {
                    ...prevState.componentState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isTabularDataActive: true,
                    consGrpList: [],
                    currentSelectItem: EMPTY_CRITERIA_CONSGRP_ENTITY,
                    selectedRows: []
                }
            }
        });
    }

    const onSaveClicked = () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }


    const onEdit = (currentTarCodeEty: CriteriaEntity) => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isRead: false,
                    isEditable: true,

                    editingCriteriaEntity: {
                        ...currentTarCodeEty,
                    }
                },
                componentState: {
                    ...prevState.componentState,
                    isAdd: false,
                    isRead: false,
                    isEditable: true,
                }
            }
        })
    }

    const onRadioChange = (e: any, fieldName: string) => {
        if (e.checked) {
            return criteriaDispatch(prevState => {
                return {
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        currentSelectItem: {
                            ...prevState.componentState.currentSelectItem,
                            constraints: [],
                            [fieldName]: e.key,
                        }
                    }
                }

            })
        }
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        criteriaDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingCriteriaEntity: {
                    ...prevState.masterState.editingCriteriaEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

    const onComponentCancel = () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    currentSelectItem: EMPTY_CRITERIA_CONSGRP_ENTITY,
                    isSliderOpen: false,
                    allFormState: {},
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                }
            }
        })
    }

    const onComponentSaveClicked = () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onComponentFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    currentSelectItem: {
                        ...prevState.componentState.currentSelectItem,
                        [fieldKey]: fieldValue
                    },
                    allFormState: {
                        ...prevState.componentState.allFormState,
                        [fieldKey]: '',
                    },
                }
            }
        })
    }

    const updateSelectedComponentRows = async (rows: any[]) => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    //currentSelectItem: rows[0],
                    selectedRows: rows,
                }
            }
        })
    }

    const onComponentRowClick = (row: ConstraintGroupEntity) => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    currentSelectItem: row,
                    isSliderOpen: true,
                    isRead: prevState.masterState.isRead,
                    isAdd: prevState.masterState.isAdd,
                }
            }
        });
    }

    const onComponentAdd = () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    currentSelectItem: {
                        ...EMPTY_CRITERIA_CONSGRP_ENTITY,
                        constraintGroupDefName: prevState.consGrpsTypeDropdownOptions[0]?.key ?? "",
                    },
                    isAdd: true,
                    isSliderOpen: true,
                    isEditable: false,
                    isRead: false,
                    isSaveClicked: false,
                }
            }
        })
    }

    const onSearchComponent = async (criteriaId: number) => {
        const chargeCriteria = Object.values(CriteriaExcludedPolicy.chargeCriteria);
        const allCriteria = Object.values(CriteriaExcludedPolicy.allCriteria);

        criteriaDispatch(prevState => {
            let consDefDropdownOptions: { [key: string]: DropdownProps[] } = {};
            let consDefDisNames: string[] = [];
            if (prevState.currentSelectedRow.triggeredBy === CriteriaExcludedPolicy.TRIGGEREDBYIND) {
                prevState.allConstraintDef?.forEach(consDef => {
                    if (consDef.constraintDefName === CriteriaExcludedPolicy.allCriteria.SHIPMENTTYPE_1 ||
                        consDef.constraintDefName === CriteriaExcludedPolicy.allCriteria.SHIPMENTTYPE_2) {
                        consDefDisNames.push(consDef.constraintDefDisplayName);
                    }
                    if (consDef.constraintDefName && !allCriteria.includes(consDef.constraintDefName)) {
                        consDefDisNames.push(consDef.constraintDefDisplayName);
                    }
                })
            }
            if (prevState.entrypoint !== 'CHARGE') {
                prevState.allConstraintDef?.forEach(consDef => {
                    if (consDef.constraintDefName && !chargeCriteria.includes(consDef.constraintDefName)
                        && !consDefDisNames.includes(consDef.constraintDefDisplayName)) {
                        consDefDisNames.push(consDef.constraintDefDisplayName);
                    }
                })

                for (const key in prevState.dynamicOptions.consDefDropdownOptions) {
                    if (prevState.dynamicOptions.consDefDropdownOptions.hasOwnProperty(key)) {
                        consDefDropdownOptions[key] = prevState.dynamicOptions.consDefDropdownOptions[key]?.filter(item => consDefDisNames.includes(item.value))
                    }
                }
            } else {
                consDefDropdownOptions = prevState.dynamicOptions.consDefDropdownOptions;
            }



            return {
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    criteriaComponentList: [],
                    consGrpList: [],
                    selectedRows: [],
                },
                dynamicOptions: {
                    ...prevState.dynamicOptions,
                    consDefDropdownOptions: consDefDropdownOptions
                },
            }
        })

        criteriaRepo.getConsGrpByCriteriaId(criteriaId).then((data) => {
            criteriaDispatch(prevState => {
                return {
                    ...prevState,
                    componentState: {
                        ...prevState.componentState,
                        consGrpList: data,
                        selectedRows: [],
                        currentSelectItem: EMPTY_CRITERIA_CONSGRP_ENTITY,
                    },
                    masterState: {
                        ...prevState.masterState,
                        editingCriteriaEntity: EMPTY_CRITERIA_ENTITY,
                    }
                }
            })
        })
    }

    const onSetEntrypoint = async (entrypoint: string) => {

        criteriaDispatch(prevState => {
            return {
                ...prevState,
                entrypoint: entrypoint
            }
        })
    }



    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }


        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingCriteriaEntity: {
                        ...prevState.masterState.editingCriteriaEntity,
                        [fieldKey]: val,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        });
    }

    const getConsGroupDefValue = async (key: string): Promise<DropdownProps[]> => {
        try {
            const data = await criteriaRepo.getConsGroupDefValue(key);
            if (data) {
                let consValueDropdownOptions: DropdownProps[] = [];
                data?.forEach((item) => {
                    if (!consValueDropdownOptions?.some(v => v.value === item)) {
                        consValueDropdownOptions.push({
                            dropdownLabel: item,
                            tagLabel: item,
                            value: item
                        })
                    }
                })
                return consValueDropdownOptions;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }

    }

    const onDeleteGroup = async (conGrp: ConstraintGroupEntity) => {
        criteriaDispatch(prevState => {
            let consGrpList = prevState.componentState.consGrpList;
            consGrpList?.forEach(consGrps => {
                if (consGrps.id === conGrp.id) {
                    consGrps.constraints = [];
                }
            })
            return {
                ...prevState,
                componentState: {
                    ...prevState.componentState,
                    consGrpList: consGrpList,
                },
            }
        })
    }

    return {
        onHeaderMultipleDropdownChange: onHeaderMultipleDropdownChange,
        onHeaderSingleDropdownChange: onHeaderSingleDropdownChange,
        onHeaderFieldChange: onHeaderFieldChange,
        loadDropdownOption: loadDropdownOption,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onRowDoubleClick: onRowDoubleClick,
        updateSelectedRows: updateSelectedRows,
        onSearchClick: onSearchClick,
        searchCriteria: searchCriteria,
        onAddClick: onAddClick,
        onResetClick: onResetClick,
        onTempSaveDetail: onTempSaveDetail,
        onSave: onSave,
        onCloseClick: onCloseClick,
        onSaveClicked: onSaveClicked,
        onEdit: onEdit,
        onRadioChange: onRadioChange,
        onCheckboxChange: onCheckboxChange,
        onComponentCancel: onComponentCancel,
        onComponentSaveClicked: onComponentSaveClicked,
        onComponentFieldChange: onComponentFieldChange,
        updateSelectedComponentRows: updateSelectedComponentRows,
        onComponentRowClick: onComponentRowClick,
        onComponentAdd: onComponentAdd,
        onSearchComponent: onSearchComponent,
        onSetEntrypoint: onSetEntrypoint,
        onFieldChange: onFieldChange,
        loadConsGrpTypeDropdownOption: loadConsGrpTypeDropdownOption,
        getConsGroupDefValue: getConsGroupDefValue,
        onApply: onApply,
        onDeleteGroup: onDeleteGroup,
    }
}