import type { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CriteriaConstant } from "./CriteriaConstant";

const CRITERIA_MAIN_CONSTANT = CriteriaConstant.Table;

export const INITIAL_CRITERIA_COMP_COL_DEF: any[] = [
    {
        headerName: CRITERIA_MAIN_CONSTANT.NAME,
        field: "constraintGroupName",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 200,
        checkboxSelection: true,
        headerCheckboxSelection: true,

    },
    {
        headerName: CRITERIA_MAIN_CONSTANT.TYPE,
        field: "constraintGroupDefName",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 130,
    },
]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, [], [], [], cellRenderers);
});
